import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Scheduler from '../views/scheduler/Scheduler.vue'
import Login from '../components/core/Login.vue';
import Order from '../views/order/Order.vue'
import ArchivePharmacy from '../views/order/ArchivePharmacy.vue'
import ArchiveMP from '../views/order/ArchiveMP.vue'
import ArchiveFilial from '../views/order/ArchiveFilial.vue'
import Discount from '../views/order/Discount.vue'
import Template from '../views/order/Template.vue'
import City from '../views/catalog/City.vue'
import User from '../views/catalog/User.vue'
import Distributor from '../views/catalog/Distributor.vue'
import DistributorControl from '../views/management/DistributorControl.vue'
import Goods from '../views/catalog/Goods.vue'
import Annotation from '../views/catalog/Annotation.vue'
import Price from '../views/catalog/Price.vue'
import PriceControl from '../views/management/PriceControl.vue'
import ContractControl from '../views/management/ContractControl.vue'
import ShippingAddressControl from '../views/management/ShippingAddressControl.vue'
import Chat from '../views/chat/Chat.vue'
import PriceAnalysisReport from '../views/report/PriceAnalysisReport.vue';
import UserControl from '../views/management/UserControl.vue'
import CompanyControl from '../views/management/CompanyControl.vue'
import AccessControl from '../views/management/AccessControl.vue'
import AccessTemplateControl from '../views/management/AccessTemplateControl.vue'
import HalykCardsControl from '../views/management/HalykCardsControl.vue'
import AdControl from '../views/management/AdControl.vue'
import OrderControl from '../views/management/OrderControl.vue'
import Hangfire from '../views/management/Hangfire.vue'
import NotFound from '../views/error/NotFound.vue';
import Test from '../views/Test.vue'
import APIRequestControl from '../views/management/APIRequestControl.vue'
import ResetPassword from '../components/core/ResetPassword.vue';
import store from './../store'
import ArticleControl from '../views/management/ArticleControl.vue';
import UserAccessControl from '../views/management/UserAccessControl.vue';
import FilialLine from '../views/setup/FilialLine.vue';
import Positions from '../components/medicalRepresentativeCabinet/positions/Positions.vue'
import Groups from '../components/medicalRepresentativeCabinet/groups/Groups.vue'
import Intelligence from '../components/medicalRepresentativeCabinet/discounts/intelligence/Intelligence.vue'
import MpPharms from '../components/medicalRepresentativeCabinet/pharms/MpPharms.vue'
import Total from '../components/medicalRepresentativeCabinet/discounts/Total/Total.vue'

Vue.use(VueRouter)

const routes = [{ path: '*', component: NotFound }, {

  path: '/test',
  name: 'test',
  component: Test,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Тест',
      disabled: true,
      href: '',
    }
    ]
  },
}, {
  path: '/',
  name: 'home',
  component: Home,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: true,
      href: '',
    }]
  },
},
{

  path: '/scheduler',
  name: 'scheduler',
  component: Scheduler,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Планировщик',
      disabled: true,
      href: '',
    }
    ]
  },
},
{
  path: '/order',
  name: 'order',
  component: Order,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: '/',
    },
    {
      text: 'Потребность',
      disabled: true,
      href: 'order',
    }
    ]
  },
},
{
  path: '/discount',
  name: 'discount',
  component: Discount,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: '/',
    },
    {
      text: 'Скидки',
      disabled: true,
      href: 'discount',
    }
    ]
  },
},
{
  path: '/archivepharmacy',
  name: 'archivepharmacy',
  component: ArchivePharmacy,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: '/',
    },
    {
      text: 'Архив',
      disabled: true,
      href: 'archivepharmacy',
    }
    ]
  },
},
{
  path: '/ArchiveMP',
  name: 'ArchiveMP',
  component: ArchiveMP,
  meta: {
    breadcrumb: [
    {
      text: 'Архив',
      disabled: true,
      href: 'ArchiveMP',
    }
    ]
  },
},
{
  path: '/archivefilial',
  name: 'archivefilial',
  component: ArchiveFilial,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: '/',
    },
    {
      text: 'Архив',
      disabled: true,
      href: 'archivefilial',
    }
    ]
  },
},
{
  path: '/templates',
  name: 'templates',
  component: Template,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: '/',
    },
    {
      text: 'Шаблоны',
      disabled: true,
      href: 'templates',
    }
    ]
  },
},
{
  path: '/user',
  name: 'user',
  component: User,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Пользователи',
      disabled: true,
      href: '/User',
    },
    ]
  },
},
{
  path: '/priceanalysisreport',
  name: 'priceanalysisreport',
  component: PriceAnalysisReport,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Ценовой анализ',
      disabled: true,
      href: 'priceanalysisreport',
    },
    ]
  },
},
{
  path: '/usercontrol',
  name: 'usercontrol',
  component: UserControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Пользователи',
      disabled: true,
      href: 'usercontrol',
    },
    ]
  },
},
{
  path: '/companycontrol',
  name: 'companycontrol',
  component: CompanyControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Компания',
      disabled: true,
      href: 'companycontrol',
    },
    ]
  },
},
{
  path: '/accesscontrol',
  name: 'accesscontrol',
  component: AccessControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Доступы',
      disabled: true,
      href: 'accesscontrol',
    },
    ]
  },
},
{
  path: '/accesstemplatecontrol',
  name: 'accesstemplatecontrol',
  component: AccessTemplateControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Шаблоны доступов',
      disabled: true,
      href: 'accesstemplatecontrol',
    },
    ]
  },
},
{
  path: '/halykcardscontrol',
  name: 'halykcardscontrol',
  component: HalykCardsControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Карты халык банка',
      disabled: true,
      href: 'halykcardscontrol',
    },
    ]
  },
},
{
  path: '/adcontrol',
  name: 'adcontrol',
  component: AdControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Реклама',
      disabled: true,
      href: 'adcontrol',
    },
    ]
  },
},
{
  path: '/ordercontrol',
  name: 'ordercontrol',
  component: OrderControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Заказы',
      disabled: true,
      href: 'ordercontrol',
    },
    ]
  },
},
// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
// },
{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    allowAnonymous: true
  }
},
{
  path: '/resetpassword',
  name: 'resetpassword',
  component: ResetPassword
},
{
  path: '/city',
  name: 'city',
  component: City,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Города',
      disabled: true,
      href: 'city',
    }
    ]
  },
},
{
  path: '/distributor',
  name: 'distributor',
  component: Distributor,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Поставщики',
      disabled: true,
      href: 'distributor',
    }
    ]
  },
},
{
  path: '/distributorcontrol',
  name: 'distributorcontrol',
  component: DistributorControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Поставщики',
      disabled: true,
      href: 'distributorcontrol',
    }
    ]
  },
},
{
  path: '/goods',
  name: 'goods',
  component: Goods,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Товары',
      disabled: true,
      href: 'goods',
    }
    ]
  },
},
{
  path: '/annotation',
  name: 'annotation',
  component: Annotation,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Аннотация',
      disabled: true,
      href: 'annotation',
    }
    ]
  },
},
{
  path: '/price',
  name: 'price',
  component: Price,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Прайсы',
      disabled: true,
      href: 'price',
    }
    ]
  },
},
{
  path: '/pricecontrol',
  name: 'pricecontrol',
  component: PriceControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Прайсы',
      disabled: true,
      href: 'pricecontrol',
    }
    ]
  },
},
{
  path: '/hangfire',
  name: 'hangfire',
  component: Hangfire,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'hangfire',
      disabled: true,
      href: 'hangfire',
    }
    ]
  },
},
{
  path: '/contractcontrol',
  name: 'contractcontrol',
  component: ContractControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Скидки и Договоры',
      disabled: true,
      href: 'contractcontrol',
    }
    ]
  },
},
{
  path: '/apirequestcontrol',
  name: 'apirequestcontrol',
  component: APIRequestControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Журнал запросов',
      disabled: true,
      href: 'apirequestcontrol',
    }
    ]
  },
},
{
  path: '/shippingAddresscontrol',
  name: 'shippingAddresscontrol',
  component: ShippingAddressControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Адреса доставки',
      disabled: true,
      href: 'shippingAddresscontrol',
    }
    ]
  },
},
{
  path: '/chat',
  name: 'chat',
  component: Chat,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Чат',
      disabled: true,
      href: 'chat',
    }
    ]
  },
},
{
  path: '/article',
  name: 'article',
  component: ArticleControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Новости',
      disabled: true,
      href: 'article',
    }
    ]
  },
},
{
  path: '/UserAccessControl',
  name: 'UserAccessControl',
  component: UserAccessControl,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: false,
      href: '/',
    },
    {
      text: 'Контроллер',
      disabled: true,
      href: 'UserAccessControl',
    }
    ]
  },
}, {
  path: '/FilialLine',
  name: 'FilialLine',
  component: FilialLine,
  meta: {
    breadcrumb: [{
      text: 'Главная',
      disabled: true,
      href: '/',
    }, {
      text: 'Линии приоритетов',
      disabled: true,
      href: 'FilialLine',
    }]
  },
},
{
  path: '/positions',
  name: 'Positions',
  component: Positions,
},
{
  path: '/groups',
  name: 'Groups',
  component: Groups,
},
{
  path: '/intelligence',
  name: 'Intelligence',
  component: Intelligence,
},
{
  path: '/mpPharms',
  name: 'MpPharms',
  component: MpPharms,
},
{
  path: '/total',
  name: 'Total',
  component: Total,
},
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === "/resetpassword") {
    store.commit('CLEAR_TOKENS');
    var data = JSON.parse(to?.query?.user);
    window.console.log(data)
    let Tokens = {
      access: data.accessToken,
      refresh: data.refreshToken
    }
    store.commit('SET_TOKENS', Tokens);
    store.commit('SET_USER', data.user);

  }

  const loginPages = ['/login'];
  const authRequired = !loginPages.includes(to.path);
  const loggedIn = localStorage.getItem('PHC_accT');

  if (authRequired && !loggedIn) {

    return next('/login');
  }
  next();

})

router.afterEach(() => {

})

export default router