<template>
  <v-app-bar
    :height="80"
    clipped-left
    app
    :collapse="!collapseOnScroll"
    elevate-on-scroll
  >
    <v-app-bar-nav-icon @click="changeDrawer(!navDrawer)" />

    <!-- <v-checkbox v-model="collapseOnScroll" hide-details></v-checkbox> -->
    <!-- <v-toolbar-title>
      <span class="hidden-sm-and-down text-h5">
        <v-img
          max-width="100"
          src="https://ph.center/Content/logo11.png"
        ></v-img>
      </span>
    </v-toolbar-title> -->
    <v-spacer />
    <UserDialog />
    <BankCardsDialog />

    <AdTemplate :adLocation="0" class="my-auto"></AdTemplate>
    <!-- <AdTemplate :adLocation="5"></AdTemplate> -->
    <AdTemplate :adLocation="2" class="my-auto"></AdTemplate>

    <!-- <div class="ma-3">
      <v-icon color="primary">mdi-bell</v-icon>
    </div> -->

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div class="ma-3">
          <v-btn text v-on="on">
            <v-icon color="primary" left>mdi-account</v-icon>
            <span class="text-capitalize primary--text font-weight-bold">{{
              user.login
            }}</span>
          </v-btn>
        </div>
      </template>
      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img
              src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.login }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ user.firstName }} {{ user.lastName }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i" @click="item.click">
            <v-list-item-icon>
              <v-icon color="primary" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  name: "Toolbar",
  data() {
    return {
      collapseOnScroll: true,
      items: [
        {
          text: "Профиль",
          icon: "mdi-account",
          click: () => {
            this.$store
              .dispatch("USER")
              .then(() => {
                this.$store.commit("SET_USER_DIALOG", true);
              })
              .catch((error) => {
                window.console.log(error);
              });
          },
        },
        // {
        //   text: "Банковские карты",
        //   icon: "mdi-credit-card-multiple-outline",
        //   click: () => {
        //     this.$store
        //       .dispatch("HALYK_CARDS_CONTEXT")
        //       .then(() => {
        //         this.$store.commit("SET_HALYK_CARDS_DIALOG", true);
        //       })
        //       .catch((error) => {
        //         window.console.log(error);
        //       });
        //   },
        // },
        // {
        //   text: "Настройки",
        //   icon: "mdi-database-settings",
        //   click: () => {},
        // },
        {
          text: "Выход",
          icon: "mdi-account-remove-outline",
          click: () => {
            this.$store
              .dispatch("CLEAR_TOKENS")
              .then(() => {})
              .catch((error) => {
                window.console.log(error);
              });
            this.$store.commit("CLEAR_EXP_NOTICE");
          },
        },
      ],
    };
  },
  methods: {
    changeDrawer(val) {
      this.$store.state.navDrawer = val;
    },
    GetUserByContext() {
      this.$store
        .dispatch("USER")
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  computed: {
    navDrawer() {
      return this.$store.state.navDrawer;
    },
    user() {
      return this.$store.getters.USER;
    },
  },
  created() {
    this.GetUserByContext();
  },
};
</script>
