<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-2 font-weight-medium">
              <v-icon>mdi-list-box-outline</v-icon>
              Общая
            </span>
          </v-card-title>
          <v-card-text> 
            <v-row>
                <v-col cols="12">
                    <h3>Общая скидка: <span class="primary--text">{{producerTotalDiscount.discount}}%</span></h3>
                </v-col>
            </v-row>        
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Total",
  computed: {
    producerTotalDiscount: {
      get: function () {
        return this.$store.getters.PRODUCER_TOTAL_DISCOUNT;
      },
    },
  },    
  components: {},
  data() {
    return {};
  },
  created() {
    this.$store
      .dispatch("GET_PRODUCER_TOTAL_DISCOUNT", null)
      .then(() => {})
      .catch(() => {});
  },
};
</script>